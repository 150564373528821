import gayora from './Gayora-Regular.woff';

export default {
  fontFamily: 'Gayora',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
        local('Gayora'),
        url('${gayora}') format('woff')
      `,
};
