import React from 'react';
import clsx from 'clsx';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { ButtonBase, Container, Grid, Link, makeStyles, Typography, useMediaQuery } from '@material-ui/core';

import AppelButtonIcon from '../assets/images/Applestore.inline.svg';
import GoogleButtonIcon from '../assets/images/Playstore.inline.svg';
// import WhatsAppIcon from '../assets/images/whatsapp.svg';
// import FacebookIcon from '../assets/images/Facebook.png';
// import InstagramIcon from '../assets/images/Instagram.png';

import background from '../assets/images/Hero-Section.svg';
import backgroundMobile from '../assets/images/Hero-Section-mobile.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${backgroundMobile})`,
    },
  },
  container: {
    maxWidth: 1056,
    paddingTop: 96,
    paddingBottom: 96,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: '83px 32px',
    },
  },
  icon: {
    height: 50,
    width: '100%',
    maxWidth: 170,
  },
  socialIcon: {
    height: 40,
    width: '100%',
    maxWidth: 40,
    marginLeft: '5px',
    cursor: 'pointer',
  },
  image: {
    filter: 'drop-shadow(10px 15px 30px rgba(41, 41, 42, 0.5))',
  },
  main: {
    backgroundImage: `url(${background})`,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${backgroundMobile})`,
    },
  },
  title: {
    wordSpacing: -20,
    [theme.breakpoints.down('sm')]: {
      wordSpacing: -10,
    },
  },
  header: {
    paddingBottom: 76,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  spacer: {
    paddingBottom: 65,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 52,
    },
  },
  spacer2: {
    paddingBottom: 32,
  },
  leftContainer: {
    paddingTop: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  contactButton: {
    padding: '10px 20px',
    backgroundColor: '#000000',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'fixed',
    bottom: '20px',
    right: '40px',
    cursor: 'pointer',
    zIndex: '9999',
    [theme.breakpoints.down('sm')]: {
      right: '10px',
    },
  },
  contactIcon: {
    height: 30,
    width: '100%',
    maxWidth: 30,
  },
  contactUsText: {
    color: '#F8E61C',
    whiteSpace: 'nowrap',
    marginLeft: '5px',
    fontWeight: 'bold',
  },
}));

export default function Home() {
  const classes = useStyles();
  const matches = useMediaQuery(theme => theme.breakpoints.down('sm')) || false;

  const { image } = useStaticQuery(graphql`
    {
      image: file(relativePath: { eq: "Mobile-iPhone-X.png" }) {
        childImageSharp {
          gatsbyImageData(formats: WEBP, layout: CONSTRAINED)
        }
      }
    }
  `);

  const handleWindowClick = link => {
    window.open(link, '_blank');
  };

  const handleEvent = key => {
    switch (key) {
      case 'Facebook':
        window.gtag('event', 'Button Clicked', {
          event_category: 'Social',
          event_label: 'Facebook',
        });
        break;
      case 'Instagram':
        window.gtag('event', 'Button Clicked', {
          event_category: 'Social',
          event_label: 'Instagram',
        });
        break;
      case 'Whatsapp':
        window.gtag('event', 'Button Clicked', {
          event_category: 'Social',
          event_label: 'Whatsapp',
        });
        break;
      case 'Apple':
        window.gtag('event', 'Download Button Clicked', {
          event_category: 'Store',
          event_label: 'Apple Store',
        });
        break;
      case 'Google':
        window.gtag('event', 'Download Button Clicked', {
          event_category: 'Store',
          event_label: 'Play Store',
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.contactButton}
        onClick={() => {
          handleWindowClick('https://wa.me/33752431685');
          handleEvent('Whatsapp');
        }}
      >
        <StaticImage src={'../assets/images/whatsapp.svg'} width="30px" height="30px" className={classes.contactIcon} />
        <Typography variant="body1" color="secondary" className={classes.contactUsText}>
          Talk to us
        </Typography>
      </div>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h2">subnight</Typography>
          <div style={{ display: 'flex' }}>
            <div
              onClick={() => {
                handleWindowClick('https://www.instagram.com/subnight.club/');
                handleEvent('Instagram');
              }}
            >
              <StaticImage
                width="40px"
                height="40px"
                src={'../assets/images/Instagram.png'}
                className={classes.socialIcon}
              />
            </div>
            <div
              onClick={() => {
                handleWindowClick('https://www.facebook.com/Subnightapp');
                handleEvent('Facebook');
              }}
            >
              <StaticImage
                width="40px"
                height="40px"
                src={'../assets/images/Facebook.png'}
                className={classes.socialIcon}
              />
            </div>
          </div>
        </div>
        {/* <div className={classes.main} /> */}
        <div className={clsx(classes.spacer)}>
          <Grid container spacing={matches ? 8 : 5} justifyContent="center">
            <Grid item xs={12} md="auto">
              <div className={classes.leftContainer}>
                {/* <Typography variant="h4" color="textSecondary" className={clsx(classes.spacer)}>
                  Exclusive membership app
                </Typography> */}
                <Typography variant="h1" className={clsx(classes.title, classes.spacer)}>
                  Your Nightlife is one tap away
                </Typography>
                <Grid
                  container
                  wrap="nowrap"
                  alignItems="center"
                  justifyContent={matches ? 'center' : 'flex-start'}
                  spacing={2}
                >
                  <Grid item onClick={() => handleEvent('Apple')}>
                    <ButtonBase
                      component="a"
                      target="_blank"
                      href="https://apps.apple.com/fr/app/subnight/id1531000274"
                    >
                      <AppelButtonIcon className={classes.icon} />
                    </ButtonBase>
                  </Grid>
                  <Grid item onClick={() => handleEvent('Google')}>
                    <ButtonBase
                      component="a"
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.subnight&hl=fr&gl=US"
                    >
                      <GoogleButtonIcon className={classes.icon} />
                    </ButtonBase>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} md>
              <GatsbyImage
                loading={matches ? 'none' : 'lazy'}
                alt="profile picture"
                image={getImage(image)}
                className={classes.image}
              />
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid
            container
            //   alignItems="center"
            justifyContent={matches ? 'center' : 'flex-start'}
            spacing={matches ? 3 : 5}
            className={classes.spacer2}
          >
            <Grid item>
              <Typography variant="h3">London</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h3">Paris</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h3">Mykonos</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h3">Dubaï</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h3">New-York</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h3">& More...</Typography>
            </Grid>
          </Grid>

          <Grid container alignItems="center" justifyContent={matches ? 'center' : 'flex-start'} spacing={9}>
            <Grid item>
              <Link href="https://www.websitepolicies.com/policies/view/kewLf7ap" target="_blank" underline="none">
                <Typography variant="body1" color="secondary" className={classes.spacer2}>
                  Terms
                </Typography>
              </Link>
            </Grid>
            <Grid item>
              <Link href="/SUBNIGHT_PRIVACY_POLICY_NEW21.pdf" target="_blank" underline="none">
                <Typography variant="body1" color="secondary" className={classes.spacer2}>
                  Privacy
                </Typography>
              </Link>
            </Grid>
          </Grid>

          <Typography variant="body1" color="secondary">
            2022 - Subnight LTD - All rights reserved
          </Typography>
        </div>
      </Container>
    </div>
  );
}
