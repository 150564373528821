import 'typeface-roboto';

import React from 'react';
import { createTheme, responsiveFontSizes, ThemeProvider, CssBaseline } from '@material-ui/core';

import gayoraFont from '../assets/fonts';

import Home from '../features/home';
import SEO from '../components/seo';

let theme = createTheme({
  palette: {
    background: {
      default: '#000000',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#F8E71C',
    },
    secondary: {
      main: 'rgba(255,255,255,0.5)',
    },
  },
  typography: {
    fontFamily: 'Gayora, Roboto',
    h1: {
      fontSize: 100,
      lineHeight: 0.9,
    },
    h2: {
      fontSize: 60,
    },
    h3: {
      fontSize: 40,
    },
    h4: {
      fontSize: 22,
      fontFamily: 'Roboto',
      fontWeight: 900,
    },
    body1: {
      fontSize: 16,
      fontFamily: 'Roboto',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [gayoraFont],
      },
    },
  },
});

theme = responsiveFontSizes(theme);

const HomePage = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SEO />
    <Home />
  </ThemeProvider>
);

export default HomePage;
